.App {
  text-align: center;
  background: white;
}

.App-link {
  color: #61dafb;
}

.react-scrollable-list {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 500px;
  width: 800px;
  overflow-y: scroll;
  border: #ddd solid 1px;
  margin: auto;
}

.react-scrollable-list-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 30px;
  padding: 5px;
  text-align: justify;
  background-color: #f2f2f2;
  border-bottom: #ddd solid 1px;
  pointer-events: none;
}

.button-container {
  display: inline-block;
  background-color: #f2f2f28c;
  padding: 0px 10px 15px;
  border: #555 solid 1px;
  margin: 10px;
}
